import StoreAuth from '@Stores/User/StoreAuth'
import { someOf } from '@Utils/Utils'
import StoreCountry from '@Stores/StoreCountry'
import StoreIoCart from '@Stores/StoreIoCart'
import { storeFirebase } from '@Stores/StoreFirebase'
import { storeManager } from '@Stores/MainStore'
import { storeProduct } from '@Stores/StoreProduct'
import { get as mget, set as mset, remove as mremove, has as mhas } from 'mobx'
import toPairs from 'lodash/toPairs'
import { isFunction, isSomething, isNaN } from '@Utils/Utils'

const toSnapshotValue = items => items.reduce((obj, r) => {
    return Object.assign(
        obj,
        {
            [r.itemCode]: {
                price: r.price,
                pv: r.pv,
                qty: r.qty,
            }
        }
    )
}, {})

const toItems = snapshotValue => Object.keys(snapshotValue).map(itemCode => {
    const { price, pv, qty } = snapshotValue[itemCode]
    return {
        itemCode,
        price,
        pv,
        qty,
    }
})

const getReference = (options = { type: 'shopping', customerID: 'auto' }) => ({
    countryCode: StoreCountry.Country3(),
    cartType: someOf(options.type, [null, undefined, '', 'auto']) ? 'shopping' : options.type,
    baId: someOf(options.customerID, [null, undefined, '', 'auto']) ? StoreAuth.GetId() : options.customerID,
})

const updateCart = (snapshotValue) => {
    return new Promise((resolve) => {
        try {
            storeFirebase.snapshotValue = snapshotValue

            if (isSomething(snapshotValue)) {
                let totalPrice = 0, totalPV = 0, totalQty = 0
                toPairs(snapshotValue).map(([id, value]) => {
                    const { qty, price, pv } = value
                    totalPrice += price * qty
                    totalPV += pv * qty
                    totalQty += qty
                })

                storeFirebase.totalPrice = totalPrice
                storeFirebase.totalPV = totalPV
                storeFirebase.totalQty = totalQty
            } else {
                storeFirebase.totalPrice = 0
                storeFirebase.totalPV = 0
                storeFirebase.totalQty = 0
            }

            // replaced current mobx cart with firebase cart
            storeFirebase.cart = snapshotValue

            resolve()
        } catch (err) {
            console.error(err)
        }

    })
}

export const initIoCart = () => {
    if (StoreAuth.isAuthorized) {
        const refObj = getReference()
        StoreIoCart.open(
            refObj,
            {
                onConneced: () => StoreIoCart.getLastUpdate(),
                onData: e => {
                    const { data: items = [] } = e
                    const snapshotValue = toSnapshotValue(items)
                    updateCart(snapshotValue)
                        .then(() => {
                            storeManager.isCartRestoredDone = true
                        })
                        .catch(console.error)
                },
            }
        )
    }
}

export const useIoCart = () => {
    const validateQty = qty => isNaN(parseInt(qty)) === false

    const update = (type, itemCode, qty) => {
        if (storeProduct.hasProduct(itemCode)) {
            const legitQty = validateQty(qty) ? parseInt(qty) : 0
            const product = storeProduct.getProduct(itemCode)
            const { price, pv = 0 } = product

            const foundItem = mhas(storeFirebase.snapshotValue, itemCode)

            if (foundItem) {
                const cartItem = mget(storeFirebase.snapshotValue, itemCode)
                const newQty = (type === 'set' ? legitQty : parseInt(cartItem.qty) + legitQty)
                if (newQty > 0) {
                    const updateData = {
                        qty: newQty,
                        price: price,
                        pv: pv
                    }
                    mset(storeFirebase.snapshotValue, { [itemCode]: updateData })
                    StoreIoCart.setOne({ itemCode, ...updateData })
                } else {
                    mremove(storeFirebase.snapshotValue, itemCode)
                    StoreIoCart.remove([{ itemCode }])
                }
            } else {
                if (legitQty > 0) {
                    const updateData = {
                        qty: legitQty,
                        price: price,
                        pv: pv
                    }
                    mset(storeFirebase.snapshotValue, { [itemCode]: updateData })
                    StoreIoCart.setOne({ itemCode, ...updateData })
                } else {
                    mremove(storeFirebase.snapshotValue, itemCode)
                    StoreIoCart.remove([{ itemCode }])
                }
            }
        } else {
            mremove(storeFirebase.snapshotValue, itemCode)
            StoreIoCart.remove([{ itemCode }])
        }

        updateCart(storeFirebase.snapshotValue)
    }

    //const clear = () => StoreIoCart.clear()
    const clear = () => {

        console.log(StoreIoCart.data);
        let len = StoreIoCart.data.length;
        for(let i = 0; i < len; i++) {
            let itemCode = StoreIoCart.data[i].itemCode;
            assignToCart(itemCode, 0)
        }
    }

    const set = value => {
        const items = toItems(value)
        StoreIoCart.set(items)
    }

    const addToCart = (itemCode, qty = 1) => update('add', itemCode, qty)
    const removeFromCart = (itemCode, qty = 1) => update('remove', itemCode, -qty)
    const assignToCart = (itemcode, qty) => update('set', itemcode, qty)

    const listenToItem = (itemCode, callback) => {

        if (StoreAuth.isAuthorized && StoreIoCart.data) {
            const foundItem = StoreIoCart.data.find(r => r.itemCode === itemCode)
            if (foundItem) {
                if (isFunction(callback)) {
                    const { price, pv, qty } = foundItem
                    callback({ price, pv, qty } || {})
                }
            }
        }
    }

    const unlistenToItem = () => { }

    const getCurrentCartItems = async () => StoreIoCart.data

    const removeItem = itemCode => {
        if (StoreAuth.isAuthorized) {
            const items = StoreIoCart.data.filter(r => r.itemCode !== itemCode)
            StoreIoCart.set(items)
        }
    }

    return {
        clear,
        set,
        removeFromCart,
        addToCart,
        assignToCart,
        listenToItem,
        unlistenToItem,
        getCurrentCartItems,
        removeItem,
    }
}